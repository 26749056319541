<template>
  <div>
    <table class="table table-bordered table-striped">
      <thead class="thead-dark">
        <tr v-if="latestData">
          <td rowspan="1" colspan="5" style="text-align: center; font-weight: bold; background-color: #dbdbdb;">
            <div style="height: 100%; display: flex; justify-content: space-between; align-items: center;">
              <h5>{{ side === 'CALL' ? 'PUT' : 'CALL' }} Limit</h5>
              <div class="d-flex justify-content-center align-items-center me-1">
                <svg :onclick="() => { this.isShow = !this.isShow }" v-if="!this.isShow"
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                  class="bi bi-chevron-compact-down" style="cursor: pointer;" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67" />
                </svg>
                <svg :onclick="() => { this.isShow = !this.isShow }" v-else xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" fill="currentColor" class="bi bi-chevron-compact-up" style="cursor: pointer;"
                  viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894z" />
                </svg>
              </div>
            </div>
          </td>
        </tr>
      </thead>
      <tbody v-if="this.isShow">
        <tr v-if="latestData && side === 'PUT'">
          <td></td>
          <td>
            <select v-model="callData" :onchange="(e) => handlePutCall1Change(e.target.value)">
              <option v-for="limit in CallDataLimits" :key="limit" :value="limit">
                {{ limit }}
              </option>
            </select>
            <svg v-if="callData !== 20" @click="() => handlePutCall1Change(20)" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="red" class="bi bi-x mb-1" style="cursor: pointer" viewBox="0 0 16 16">
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </td>
          <td>{{ Number(latestData.putcall1).toFixed(2) }}</td>
          <td>CALL</td>
          <td>{{ latestData.putcallAskPrice1 ? Number(latestData.putcallAskPrice1).toFixed(2) : '-' }}</td>
          <!-- <td rowspan="3" style="text-align: center; vertical-align: middle;">
              {{ latestData?.putaskPriceDifference !== null ? Number(latestData?.putaskPriceDifference).toFixed(2) : '-' }}
            </td> -->
        </tr>

        <tr v-if="latestData">
          <td>{{ latestData.date }}</td>
          <td>{{ latestData.time }}</td>
          <td>{{ side === 'CALL' ? Number(latestData.call).toFixed(2) : Number(latestData.putcall).toFixed(2) }}</td>
          <td>CALL</td>
          <td>{{ side === 'CALL' ? (latestData.callAskPrice ? Number(latestData.callAskPrice).toFixed(2) : '-') :
            (latestData.putcallAskPrice ? Number(latestData.putcallAskPrice).toFixed(2) : '-') }}</td>
          <!-- <td v-if="side === 'CALL'" rowspan="3" style="text-align: center; vertical-align: middle;">
              {{ side === 'CALL' ? (latestData?.callaskPriceDifference !== null ?
                Number(latestData?.callaskPriceDifference).toFixed(2) : '-') : (latestData?.putaskPriceDifference !== null ?
                  Number(latestData?.putaskPriceDifference).toFixed(2) : '-') }}
            </td> -->
        </tr>

        <tr v-if="latestData">
          <td>{{ latestData.dayName }}</td>
          <td>{{ Number(latestData.baseValue).toFixed(2) }}</td>
          <td>{{ side === 'CALL' ? Number(latestData.callput).toFixed(2) : Number(latestData.put).toFixed(2) }}</td>
          <td>PUT</td>
          <td>{{ side === 'CALL' ? (latestData.callputAskPrice ? Number(latestData.callputAskPrice).toFixed(2) : '-')
            : (latestData.putAskPrice ? Number(latestData.putAskPrice).toFixed(2) : '-') }}</td>
        </tr>

        <tr v-if="latestData && side === 'CALL'">
          <td></td>
          <td>
            <select v-model="putData" :onchange="(e) => handleCallPut1Change(e.target.value)">
              <option v-for="limit in PutDataLimits" :key="limit" :value="limit">
                {{ limit }}
              </option>
            </select>
            <svg v-if="putData !== 20" @click="() => handleCallPut1Change(20)" xmlns="http://www.w3.org/2000/svg"
              width="24" height="24" fill="red" class="bi bi-x mb-1" style="cursor: pointer" viewBox="0 0 16 16">
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </td>
          <td>{{ Number(latestData.callput1).toFixed(2) }}</td>
          <td>PUT</td>
          <td>{{ latestData.callputAskPrice1 ? Number(latestData.callputAskPrice1).toFixed(2) : '-' }}</td>
        </tr>

        <!-- <tr>
            <td colspan="6">
              <div style="width: auto; float: right;">
                <span>Select {{ side }} Limit : </span>
                <select :id="side.toLowerCase()" v-model="localSelectedLimit" @change="handleLimitChange">
                  <option v-for="limit in limits" :key="limit" :value="limit">{{ limit }}</option>
                </select>
              </div>
            </td>
          </tr> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import VueCookie from 'vue-cookie';

export default {
  props: {
    side: String,
    latestData: Object,
    selectedLimit: String,
    limits: Array,
    saveCallPut1: Function,
    savePutCall1: Function,
  },
  data() {
    return {
      isShow: false,
      localSelectedLimit: this.selectedLimit,
      CallDataLimits: [
        -200, -195, -190, -185, -180, -175, -170, -165, -160, -155,
        -150, -145, -140, -135, -130, -125, -120, -115, -110, -105,
        -100, -95, -90, -85, -80, -75, -70, -65, -60, -55,
        -50, -45, -40, -35, -30, -25, -20, -15, -10, -5,
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
        50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
        100, 105, 110, 115, 120, 125, 130, 135, 140, 145,
        150, 155, 160, 165, 170, 175, 180, 185, 190, 195,
        200
      ],
      // isCallEdit: false,
      callData: Number(VueCookie.get('putcall1')) || null,
      PutDataLimits: [
        -200, -195, -190, -185, -180, -175, -170, -165, -160, -155,
        -150, -145, -140, -135, -130, -125, -120, -115, -110, -105,
        -100, -95, -90, -85, -80, -75, -70, -65, -60, -55,
        -50, -45, -40, -35, -30, -25, -20, -15, -10, -5,
        0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
        50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
        100, 105, 110, 115, 120, 125, 130, 135, 140, 145,
        150, 155, 160, 165, 170, 175, 180, 185, 190, 195,
        200
      ],
      // isPutEdit: false,
      putData: Number(VueCookie.get('callput1')) || null,
    };
  },
  watch: {
    selectedLimit(newVal) {
      this.localSelectedLimit = newVal;
    },

    // latestData(newVal) {
    //   if (newVal) {
    //     if (!this.isCallEdit) {
    //       if (this.callData !== newVal.putcall1) {
    //         this.callData = newVal.putcall1;
    //         this.CallDataLimits = this.generateLimits(newVal.putcall1, 'negative', 20, 5);
    //         this.savePutCall1(newVal.putcall1);
    //       }
    //     }

    //     if (!this.isPutEdit) {
    //       if (this.putData !== newVal.callput1) {
    //         this.putData = newVal.callput1;
    //         this.PutDataLimits = this.generateLimits(newVal.callput1, 'positive', 20, 5);
    //         this.saveCallPut1(newVal.callput1);
    //       }
    //     }
    //   }
    // },
  },
  methods: {
    handleLimitChange(event) {
      event.preventDefault();
      this.$emit('update:selectedLimit', this.localSelectedLimit);
    },
    convertToJamaicaTime(value, format) {
      return moment(value).format(format);
    },
    handlePutCall1Change(value) {
      // this.isPutEdit = true;
      this.callData = value;
      this.savePutCall1(value);
    },
    handleCallPut1Change(value) {
      // this.isCallEdit = true
      this.putData = value;
      this.saveCallPut1(value);
    },

    // generateLimits(value, direction = 'positive', steps = 20, gap = 5) {
    //   const limits = [Number(value)];

    //   for (let i = 1; i <= steps; i++) {
    //     if (direction === 'positive') {
    //       limits.push(Number(value) + i * gap);
    //     } else {
    //       limits.push(Number(value) - i * gap);
    //     }
    //   }

    //   return limits;
    // }
  },
};
</script>

<style scoped>
td {
  padding: 0.2rem 0.5rem !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}
</style>
