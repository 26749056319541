// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import UserPage from "./pages/user/UserPage.vue";
// import SpxPage from "./pages/index/spx/SpxPage.vue";
import SpxPage2 from "./pages/index/spx/newSpxPage.vue";
// import SpxDataPage from "./pages/index/spx/SpxDataPage.vue";
import AuthLogin from "./pages/auth/AuthPage.vue";
// import OrderSetting from "./pages/settings/OrderSettingPage.vue";

const routes = [
  {
    path: "/",
    name: "Auth Page",
    component: AuthLogin,
  },
  {
    path: "/spx",
    name: "Spx Page",
    component: SpxPage2
  },
  // {
  //   path: "/spx2",
  //   name: "Spx Page 2",
  //   component: SpxPage2
  // },
  // {
  //   path: "/spxData",
  //   name: "Spx Data Page",
  //   component: SpxDataPage
  // },
  {
    path: "/user",
    name: "User Page",
    component: UserPage
  },
  // {
  //   path: '/orderSetting',
  //   name: "Order Setting Page",
  //   component: OrderSetting
  // }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
