<template>
    <div>
        <div class="user-header">
            <h2>
                User Data
            </h2>

            <div class="user-row d-none">
                <div v-if="showFilters" class="user-controls">
                    <!-- <div class="user-group">
                        <label for="date-select">Date:</label>
                        <input type="date" v-model="selectedDate" id="date-select">
                    </div> -->

                    <div class="user-group">
                        <label for="type-select">Allowed Index:</label>
                        <select v-model="selectedAllowedIndex" id="type-select">
                            <option disabled value="">Select Allowed Index</option>
                            <option value="all">All</option>
                            <option value="spx">SPX</option>
                        </select>
                    </div>
                </div>

                <button class="btn btn-primary" @click="toggleFilters">
                    <svg v-if="!showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    <svg v-if="showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
            </div>
        </div>

        <hr />

        <div>
            <div class="float-end mb-3">
                <label for="items-per-page-select">Select Data Per Page :</label>
                <select v-model="itemsPerPage" class="ms-2 p-2" id="items-per-page-select" @change="selectDataPerPage">
                    <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>

            <div class="data-table-container mb-5">
                <table class="data-table">
                    <thead>
                        <tr>
                            <th style="min-width: 170px;">Created Date</th>
                            <th style="min-width: 100px;">First Name</th>
                            <th style="min-width: 80px;">Last Name</th>
                            <th style="min-width: 80px;">Email</th>
                            <!-- <th style="min-width: 80px;">Is Active</th> -->
                            <th style="min-width: 80px;">Role</th>
                            <th style="min-width: 80px;">Allowed Index</th>
                            <th style="min-width: 80px;">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="!tableLoading">
                        <tr v-for="(item) in data" :key="item._id">
                            <td style="min-width: 170px;">{{
                                formatDate(item.created_at) }}</td>
                            <td style="min-width: 80px; text-align: center;">{{ item.firstName || "N/A" }}</td>
                            <td style="min-width: 80px; text-align: center;">{{ item.lastName || "N/A" }}</td>
                            <td style="min-width: 80px; text-align: center;">{{ item.email || "N/A" }}</td>
                            <!-- <td style="min-width: 80px; text-align: center;">{{ item.isLoggedIn ? 'true' : 'false' ||
                                'false' }}</td> -->
                            <td style="min-width: 80px; text-align: center;">{{ item.role }}</td>
                            <td style="min-width: 80px; text-align: center;">
                                <span v-for="(name, index) in item.allowedIndex" :key="index">
                                    {{ name }}<span v-if="index < item.allowedIndex.length - 1">, </span>
                                </span>
                            </td>
                            <td style="min-width: 80px; text-align: center;">
                                <b-button @click="this.updateUserData(item)" variant="primary"
                                    class="me-4">Update</b-button>
                                <b-button @click="(event) => this.handleDeleteUser(event, item._id)"
                                    variant="danger">Delete</b-button>
                            </td>
                        </tr>
                        <tr v-if="data.length === 0">
                            <td colspan="14" style="text-align: center; padding: 20px">No User Data Available</td>
                        </tr>
                    </tbody>
                    <tbody v-if="tableLoading">
                        <tr>
                            <td colspan="14" style="text-align: center; padding: 20px">
                                <b-spinner small></b-spinner>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pagination-controls mt-3">
                <div class="user-group me-2">
                    <select v-model="itemsPerPage" id="items-per-page-select" @change="selectDataPerPage">
                        <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <button class="btn btn-primary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
                <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
                <button class="btn btn-primary" @click="nextPage"
                    :disabled="currentPage === totalPages || totalPages === 0">Next
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';
import { useToast } from 'vue-toast-notification';
import { getUserTimezone } from '@/utils/dateUtils';

const $toast = useToast();

export default {
    name: 'UserData',
    props: {
        updateUserData: Function,
        refetchUserData: Boolean,
        handleRefetchUserData: Function,
        handleDeleteUser: Function
    },
    data() {
        return {
            // User Table
            data: [],
            selectedDate: '',
            selectedAllowedIndex: '',
            showFilters: false,
            tableLoading: false,
            totalData: 0,
            itemsPerPage: 50,
            currentPage: 1,
            itemsPerPageOptions: [50, 100, 250, 500, 1000, 2500, 5000, 10000],
        };
    },
    mounted() {
        if (VueCookie.get('authStatus') === "true") {
            if (VueCookie.get('userRole') !== 'admin') {
                this.$router.push(`/${VueCookie.get('allowedIndex').split(',')[0]}`)
            } else {
                this.fetchAllUsers()
            }
        }
    },
    watch: {
        refetchUserData(newValue) {
            if (newValue) {
                this.fetchAllUsers()
                this.handleRefetchUserData(false)
            }
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalData / this.itemsPerPage);
        }
    },
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters;
            this.resetFilters();
        },

        resetFilters() {
            this.selectedDate = '';
            this.selectedStatus = '';
            this.selectedRight = '';
        },

        formatDate(date) {
            const userTimezone = getUserTimezone();
            return moment(date).tz(userTimezone).format('MM/DD/YYYY hh:mm:ss');
        },

        selectDataPerPage() {
            this.currentPage = 1
            this.fetchAllUsers()
        },

        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
                this.fetchAllUsers();
            }
        },

        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
                this.fetchAllUsers();
            }
        },

        async fetchAllUsers() {
            const params = new URLSearchParams();

            if (this.selectedAllowedIndex !== '' || this.selectedAllowedIndex !== 'all') {
                params.append('allowedIndex', this.selectedAllowedIndex);
            }

            if (this.currentPage) {
                params.append('skip', this.currentPage);
            }

            if (this.itemsPerPage) {
                params.append('limit', this.itemsPerPage)
            }

            const queryString = new URLSearchParams(params).toString();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/user/getAllUser?${queryString}`;
            try {
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                if (response.ok) {
                    const jsonData = await response.json();
                    console.log('Users Fetched successfully', jsonData);
                    this.data = jsonData.data.data || [];
                    this.totalData = jsonData.data.count;
                } else {
                    console.error('User Fetching Failed:', response.status);
                    $toast.open({
                        message: "User Fetching Failed!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                }
            } catch (error) {
                console.error('Error during user Fetching request:', error);
            }
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 15px;
}

svg {
    margin-bottom: 4px;
}

.user-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.user-controls {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    justify-content: space-between;
}

.user-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
}

.user-group {
    display: flex;
    flex-direction: column;
}

.user-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.user-group input,
.user-group select {
    padding: 6px;
}

.userForm {
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #2626265c;
}

#type-select,
#start-difference,
#end-difference {
    width: 135px;
}

.search-button:hover {
    background-color: #0056b3;
}

/* Scrollable table styles */
.data-table-container {
    width: 100%;
    height: 350px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
}

.data-table {
    width: 100%;
    height: 100%;
}

.data-table thead {
    height: 30px;
}

.data-table tbody {
    height: 235px;
    padding-top: 30px;
}

.data-table th {
    text-align: center;
}

.data-table th,
.data-table td {
    width: auto;
    padding: 8px;
    outline: 1px solid #ddd;
}

/* Sticky header */
.data-table th {
    background-color: yellow;
    position: sticky;
    top: 0;
    z-index: 2;
}


/* Pagination controls */
.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

/* Media query for responsiveness */
@media (max-width: 1200px) {
    .user-header {
        display: block;
    }

    .user-row {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
