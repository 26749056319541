<template>
    <div>
        <div>
            <AppHeader />
        </div>

        <div class="container mt-5">
            <div class="w-100 d-flex align-items-center justify-content-end mb-2">
                <b-button @click="this.handleUserFormIsShow(true)">Add User</b-button>
            </div>

            <div>
                <UserData :refetchUserData="this.refetchUserData" :handleRefetchUserData="this.handleRefetchUserData"
                    :updateUserData="this.updateUserData" :handleDeleteUser="this.handleDeleteUser" />
            </div>
        </div>

        <div v-if="this.isShow"
            class="userForm vw-100 vh-100 position-absolute d-flex align-items-center justify-content-center">
            <b-card class="p-1 mx-auto" style="min-width: 340px; max-width: 600px; margin: 15px !important;">
                <h4 class="text-center mb-4">{{ this.isEdit ? 'Edit User Form' : 'Add User Form' }}</h4>
                <b-form
                    @submit="(event) => this.isEdit ? handleUserEditFormSubmit(event) : this.handleUserAddFormSubmit(event)">
                    <b-row>
                        <b-col cols="12" sm="12" md="6">
                            <b-form-group id="input-group-3" label="First Name:" label-for="input-3">
                                <b-form-input id="input-3" v-model="this.form.firstName" type="text"
                                    placeholder="Enter First Name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="12" md="6">
                            <b-form-group id="input-group-4" label="Lat Name:" label-for="input-4">
                                <b-form-input id="input-4" v-model="this.form.lastName" type="text"
                                    placeholder="Enter Last Name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="12" md="6">
                            <b-form-group id="input-group-1" label="Email address:" label-for="input-1">
                                <b-form-input id="input-1" v-model="this.form.email" type="email"
                                    placeholder="Enter email" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="!this.isEdit" cols="12" sm="12" md="6">
                            <b-form-group id="input-group-2" label="Password:" label-for="input-2">
                                <b-form-input id="input-2" v-model="this.form.password" type="password"
                                    placeholder="Enter password" required></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="12" md="6">
                            <b-form-group label="User Role:" v-slot="{ ariaDescribedby }">
                                <b-form-radio v-model="this.form.role" :aria-describedby="ariaDescribedby"
                                    name="user-role" value="user">User</b-form-radio>
                                <b-form-radio v-model="this.form.role" :aria-describedby="ariaDescribedby"
                                    name="admin-role" value="admin">Admin</b-form-radio>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="12" md="6">
                            <b-form-group label="User Allowed Index:" v-slot="{ ariaDescribedby }">
                                <b-form-checkbox-group id="checkbox-allowedIndex" v-model="this.form.allowedIndex"
                                    :options="this.allIndexData" :aria-describedby="ariaDescribedby"
                                    name="allowedIndex"></b-form-checkbox-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                        <b-button @click="this.handleUserFormIsShow(false)" variant="secondary"
                            class="me-2">Close</b-button>
                        <b-button type="submit" variant="primary">{{ this.isEdit ? 'Edit User' : 'Add User'
                            }}</b-button>
                    </div>
                </b-form>
            </b-card>
        </div>
    </div>
</template>

<script>
import VueCookie from 'vue-cookie';
import { useToast } from 'vue-toast-notification';
import AppHeader from '@/components/AppHeader.vue';
import UserData from '@/components/UserData.vue';

// Initialize toast notifications
const $toast = useToast();
export default {
    components: {
        AppHeader,
        UserData
    },
    data() {
        return {
            // User Form Var
            userId: '',
            isShow: false,
            isEdit: false,
            form: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                role: '',
                allowedIndex: []
            },
            allIndexData: ['spx'],
            refetchUserData: false
        };
    },
    mounted() {
        if (VueCookie.get('authStatus') !== "true") {
            this.$router.push('/')
        }

        if (VueCookie.get('authStatus') === "true") {
            if (VueCookie.get('userRole') !== 'admin') {
                this.$router.push(`/${VueCookie.get('allowedIndex').split(',')[0]}`)
            }
        }
    },
    methods: {
        handleUserFormIsShow(value) {
            this.isShow = value

            if (!value) {
                this.userId = ""
                this.form.firstName = ""
                this.form.lastName = ""
                this.form.email = ""
                this.form.role = ""
                this.form.allowedIndex = []

                this.isEdit = false
            }
        },

        handleUserFormIsEdit(value) {
            this.isEdit = value
        },

        handleRefetchUserData(value) {
            this.refetchUserData = value
        },

        async handleUserAddFormSubmit(event) {
            event.preventDefault();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/signup`;
            const userData = this.form

            console.log(userData, url)

            if (this.form.allowedIndex.length === 0) {
                $toast.open({
                    message: "Please Select At Least One Index.",
                    type: 'default',
                    duration: 3000,
                    dismissible: true,
                    queue: true,
                });

                return
            }

            try {
                const response = await fetch(url, {
                    method: 'POST', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(userData), // Convert the data to JSON string
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('User Register successfully', data);
                    $toast.open({
                        message: "User Register successfully!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                } else {
                    console.error('User Registration Failed:', response.status);
                    $toast.open({
                        message: "User Registration Failed!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                }

                this.handleUserFormIsShow(false)
                this.handleRefetchUserData(true)
            } catch (error) {
                console.error('Error during user registration request:', error);
            }
        },

        async handleUserEditFormSubmit(event) {
            event.preventDefault();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/user/update/${this.userId}`;
            const userData = this.form

            console.log(userData, this.userId, url)

            if (this.form.allowedIndex.length === 0) {
                $toast.open({
                    message: "Please Select At Least One Index.",
                    type: 'default',
                    duration: 3000,
                    dismissible: true,
                    queue: true,
                });

                return
            }

            try {
                const response = await fetch(url, {
                    method: 'PATCH', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(userData), // Convert the data to JSON string
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('User Updated successfully', data);
                    $toast.open({
                        message: "User Updated successfully!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                } else {
                    console.error('User Update Process Failed:', response.status);
                    $toast.open({
                        message: "User Update Process Failed!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                }

                this.handleUserFormIsShow(false)
                this.handleRefetchUserData(true)

                this.userId = ""
                this.form.firstName = ""
                this.form.lastName = ""
                this.form.email = ""
                this.form.role = ""
                this.form.allowedIndex = []
            } catch (error) {
                console.error('Error during user update process request:', error);
            }
        },

        async handleDeleteUser(event, userId) {
            event.preventDefault();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/user/delete/${userId}`;

            try {
                const response = await fetch(url, {
                    method: 'DELETE', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('User Deleted successfully', data);
                    $toast.open({
                        message: "User Deleted successfully!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                } else {
                    console.error('User Deleted Process Failed:', response.status);
                    $toast.open({
                        message: "User Deleted Process Failed!",
                        type: 'default',
                        duration: 3000,
                        dismissible: true,
                        queue: true,
                    });
                }

                this.handleUserFormIsShow(false)
                this.handleRefetchUserData(true)
            } catch (error) {
                console.error('Error during user update process request:', error);
            }
        },

        updateUserData(userData) {
            this.userId = userData._id
            this.form.firstName = userData.firstName
            this.form.lastName = userData.lastName
            this.form.email = userData.email
            this.form.role = userData.role
            this.form.allowedIndex = userData.allowedIndex

            this.handleUserFormIsShow(true)
            this.handleUserFormIsEdit(true)
        }
    }
};
</script>

<style>
h1 {
    color: #42b983;
    text-align: center;
}

.userForm {
    top: 0;
    right: 0;
    z-index: 10;
    background-color: #2626265c;
}
</style>