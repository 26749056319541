import VueCookie from 'vue-cookie';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

const showToast = (message, type) => {
    $toast.open({
        message,
        type,
        duration: 3000,
        dismissible: true,
        queue: true,
    });
}

const handleOrder = async (action, quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right, isForceSell = false) => {
    const orderDetails = {
        symbol: 'SPX',
        quantity,
        orderType,
        lmtPrice,
        auxPrice,
        trailingPercent,
        account: process.env.VUE_APP_ACCOUNT_ID,
        secType: 'OPT',
        exchange: 'SMART',
        strike,
        strikeBaseValue,
        expiry,
        right,
        isForceSell
    };

    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${VueCookie.get('authToken')}`
            },
            body: JSON.stringify(orderDetails),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        showToast(data.message, 'success');
    } catch (error) {
        console.error(`Error placing ${action} order:`, error);
    }
}

const handleRecurringOrder = async (orderDetails) => {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/recurringOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${VueCookie.get('authToken')}`
            },
            body: JSON.stringify(orderDetails),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        showToast(data.message, 'success');
    } catch (error) {
        console.error(`Error placing order:`, error);
        showToast(`Error placing order: ${error.message}`, 'error');
    }
}

export const buyOrder = async (quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right) => {
    await handleOrder('buy', quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right)
}

export const sellOrder = async (quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right) => {
    await handleOrder('sell', quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right)
}

export const closeOrder = async (quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right, isForceSell) => {
    await handleOrder('close', quantity, orderType, lmtPrice, auxPrice, trailingPercent, strike, strikeBaseValue, expiry, right, isForceSell)
}

export const recurringBuyOrder = async (quantity, orderType, strike, strikeBaseValue, expiry, right, recurringTime) => {
    const now = new Date();
    const formattedUTCDate = now.toISOString().replace('Z', '+00:00');

    const orderDetails = {
        symbol: 'SPX',
        quantity,
        orderType,
        account: process.env.VUE_APP_ACCOUNT_ID,
        secType: 'OPT',
        exchange: 'SMART',
        strike,
        strikeBaseValue,
        expiry,
        right,
        orderAction: 'BUY',
        recurringStatus: true,
        recurringTime,
        recurringStartTime: formattedUTCDate,
        isRecurringOrder: true
    };

    await handleRecurringOrder(orderDetails)
}

export const recurringStopBuyOrder = async (right) => {
    const now = new Date();
    const formattedUTCDate = now.toISOString().replace('Z', '+00:00');

    const orderDetails = { 
        right, 
        recurringStatus: false, 
        recurringEndTime: formattedUTCDate 
    }

    await handleRecurringOrder(orderDetails)
}

