<template>
    <div>
        <div class="order-header d-none">
            <div class="d-flex align-items-center justify-content-center">
                <h2>
                    <b-badge variant="success" class="ms-2 p-2 text-white">
                        <span class="small">Base Value : {{ formatBaseValue() }} </span>
                    </b-badge>
                </h2>
            </div>


            <div class="order-row">
                <div v-if="showFilters" class="order-controls">
                    <div class="order-group">
                        <label for="date-select">Date:</label>
                        <input type="date" v-model="selectedDate" id="date-select" @change="streamData">
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Action:</label>
                        <select v-model="selectedAction" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Action</option>
                            <option value="all">All</option>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Status:</label>
                        <select v-model="selectedStatus" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Status</option>
                            <option value="all">All</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pending">Pending</option>
                            <option value="Filled">Filled</option>
                            <option value="Inactive">Inactive</option>
                            <option value="PreSubmitted">Pre Submitted</option>
                            <option value="Submitted">Submitted</option>
                        </select>
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Type:</label>
                        <select v-model="selectedType" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Type</option>
                            <option value="all">All</option>
                            <option value="MKT">MKT</option>
                            <option value="LMT">LMT</option>
                            <option value="TRAIL">TRAIL</option>
                        </select>
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Right:</label>
                        <select v-model="selectedRight" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Right</option>
                            <option value="all">All</option>
                            <option value="C">CALL</option>
                            <option value="P">PUT</option>
                        </select>
                    </div>
                </div>

                <button class="btn btn-primary" @click="toggleFilters">
                    <svg v-if="!showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    <svg v-if="showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
            </div>
        </div>

        <!-- <hr /> -->

        <div>
            <div class="data-table-container" @scroll="handleScroll" ref="tableContainer">
                <div class="data-table">
                    <thead>
                        <th style="min-width: 170px;" class="sticky-col sticky-col-th-1">Placed Date</th>
                        <th style="min-width: 128px;">User</th>
                        <!-- <th style="min-width: 100px;">Date</th> -->
                        <th style="min-width: 120px;">Order Id</th>
                        <th style="min-width: 120px;">Parent Id</th>
                        <!-- <th style="min-width: 80px;">Recurring</th> -->
                        <!-- <th style="min-width: 80px;">Symbol</th> -->
                        <!-- <th style="min-width: 80px;">Exchange</th> -->
                        <th style="min-width: 75px;">Action</th>
                        <!-- <th style="min-width: 80px;">Order Type</th> -->
                        <!-- <th style="min-width: 90px;">Sec Type</th> -->
                        <!-- <th style="min-width: 80px;">Trading Class</th> -->
                        <th style="min-width: 80px;">Quantity</th>
                        <th style="min-width: 80px;">Price</th>
                        <th style="min-width: 120px;">Base Price</th>
                        <!-- <th style="min-width: 80px;">Right</th> -->
                        <!-- <th style="min-width: 80px;">Avg Fill Price</th> -->
                        <!-- <th style="min-width: 80px;">Commission</th> -->
                        <th style="min-width: 80px;">Status</th>
                        <th style="min-width: 130px;" class="sticky-col  sticky-col-th-17">
                            <div class="d-flex justify-content-center align-items-center">
                                <span class="me-2">Duration</span>
                                <div v-if="this.isRecurringSell">
                                    <select v-if="this.side === 'CALL'" v-model="this.selectedRecurringSellCallDuration"
                                        id="call-duration" :disabled="this.isSettingLoading"
                                        :onChange="(e) => this.saveRecurringSellCallDuration(e.currentTarget.value)">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                    <select v-else v-model="this.selectedRecurringSellPutDuration" id="put-duration"
                                        :disabled="this.isSettingLoading"
                                        :onChange="(e) => this.saveRecurringSellPutDuration(e.currentTarget.value)">
                                        <option v-for="duration in durationArr" :key="duration" :value="duration">
                                            {{ duration }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </th>
                        <th style="min-width: 148px;" class="sticky-col  sticky-col-th-18">Actions</th>
                    </thead>

                    <tbody v-if="!tableLoading && side === 'CALL'">
                        <tr v-for="(item) in data.filter((order) => order.right === 'C')" :key="item.orderId"
                            :class="(item.isRecurringOrder ? 'callRow' : 'callRow isMarket')">
                            <td style="min-width: 170px;" class="sticky-col sticky-col-td-1">{{
                                formatDate(item.created_at) }}</td>
                            <td style="min-width: 128px;">{{ item?.userDetails[0]?.firstName + " " +
                                item?.userDetails[0]?.lastName }}</td>
                            <!-- <td style="min-width: 100px;">
                                {{ item.expiry !== 'Invalid date' ? `${item.expiry.slice(4, 6)}/${item.expiry.slice(6,
                                    8)}/${item.expiry.slice(0, 4)}` : item.expiry }}
                            </td> -->
                            <td style="min-width: 120px;">{{ item.orderId }}</td>
                            <td style="min-width: 120px;">{{ item.parentId || "N/A" }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.isRecurringOrder ? 'True' : 'False' }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.symbol }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item?.exchange }}</td> -->
                            <td style="min-width: 75px;">{{ item.orderAction }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.orderType }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.secType }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.tradingClass }}</td> -->
                            <td style="min-width: 80px;">{{ item.quantity }}</td>
                            <td style="min-width: 80px;">{{ item.strike }}</td>
                            <td style="min-width: 120px;">{{ parseFloat(item.strikeBaseValue).toFixed(2) }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.right ? (item.right === "P" ? 'PUT' : 'CALL') : '-' }}
                            </td> -->
                            <!-- <td style="min-width: 80px;">{{ item.avgFillPrice ? item.avgFillPrice : '-' }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.commission ? item.commission : '-' }}</td> -->
                            <td style="min-width: 80px;">{{ item.orderStatus }}</td>
                            <td style="min-width: 145px;" class="sticky-col  sticky-col-td-17">
                                <p
                                    v-if="item.orderType === 'MKT' && item.orderAction === 'BUY' && item.orderStatus === 'Filled'">
                                    {{ item.scheduleDuration !== 0 ? item.scheduleDuration : item.right === "C" ?
                                        this.selectedRecurringSellCallDuration :
                                        this.selectedRecurringSellPutDuration }}</p>
                                <p v-else>N/A</p>
                            </td>
                            <td class="sticky-col sticky-col-td-18" style="min-width: 148px; text-align: center;">
                                <div v-if="item.orderType === 'MKT' && item.orderAction === 'BUY' && item.orderStatus === 'Filled'"
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <button
                                        :disabled="!this.ibkrServerStatus || item.isSold === 'Scheduled' || item.isSold === 'Rejected' || item.isSold === 'Submitted' || this.latestData === null"
                                        @click="sellOrder(item)" class="btn btn-danger btn-sm">
                                        Sell
                                    </button>

                                    <button @click="directSellOrder(item)" v-if="item.isSold === 'Scheduled'"
                                        :disabled="!this.ibkrServerStatus || item.isSold === 'Submitted' || item.isSold === 'Rejected' || this.latestData === null"
                                        class="btn btn-danger btn-sm" style="min-width: 80px; margin-left: 10px;">
                                        Direct Sell
                                    </button>
                                </div>
                                <p v-else>N/A</p>
                            </td>
                        </tr>
                        <tr v-if="data.length === 0">
                            <td colspan="14" style="min-width: 1294px; text-align: center; padding: 20px">No Call Data
                                Available</td>
                        </tr>
                    </tbody>

                    <tbody v-if="!tableLoading && side === 'PUT'">
                        <tr v-for="(item) in data.filter((order) => order.right === 'P')" :key="item.orderId"
                            :class="(item.isRecurringOrder ? 'putRow' : 'putRow isMarket')">
                            <td style="min-width: 170px;" class="sticky-col sticky-col-td-1">{{
                                formatDate(item.created_at) }}</td>
                            <td style="min-width: 128px;">{{ item?.userDetails[0]?.firstName + " " +
                                item?.userDetails[0]?.lastName }}</td>
                            <!-- <td style="min-width: 100px;">
                                {{ item.expiry !== 'Invalid date' ? `${item.expiry.slice(4, 6)}/${item.expiry.slice(6,
                                    8)}/${item.expiry.slice(0, 4)}` : item.expiry }}
                            </td> -->
                            <td style="min-width: 120px;">{{ item.orderId }}</td>
                            <td style="min-width: 120px;">{{ item.parentId || "N/A" }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.isRecurringOrder ? 'True' : 'False' }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.symbol }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item?.exchange }}</td> -->
                            <td style="min-width: 75px;">{{ item.orderAction }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.orderType }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.secType }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.tradingClass }}</td> -->
                            <td style="min-width: 80px;">{{ item.quantity }}</td>
                            <td style="min-width: 80px;">{{ item.strike }}</td>
                            <td style="min-width: 120px;">{{ parseFloat(item.strikeBaseValue).toFixed(2) }}</td>
                            <!-- <td style="min-width: 80px;">{{ item.right ? (item.right === "P" ? 'PUT' : 'CALL') : '-' }}
                            </td> -->
                            <!-- <td style="min-width: 80px;">{{ item.avgFillPrice ? item.avgFillPrice : '-' }}</td> -->
                            <!-- <td style="min-width: 80px;">{{ item.commission ? item.commission : '-' }}</td> -->
                            <td style="min-width: 80px;">{{ item.orderStatus }}</td>
                            <td style="min-width: 145px;" class="sticky-col  sticky-col-td-17">
                                <p
                                    v-if="item.orderType === 'MKT' && item.orderAction === 'BUY' && item.orderStatus === 'Filled'">
                                    {{ item.scheduleDuration !== 0 ? item.scheduleDuration : item.right === "C" ?
                                        this.selectedRecurringSellCallDuration :
                                        this.selectedRecurringSellPutDuration }}</p>
                                <p v-else>N/A</p>
                            </td>
                            <td class="sticky-col sticky-col-td-18" style="min-width: 148px; text-align: center;">
                                <div v-if="item.orderType === 'MKT' && item.orderAction === 'BUY' && item.orderStatus === 'Filled'"
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <button
                                        :disabled="!this.ibkrServerStatus || item.isSold === 'Scheduled' || item.isSold === 'Rejected' || item.isSold === 'Submitted' || this.latestData === null"
                                        @click="sellOrder(item)" class="btn btn-danger btn-sm">
                                        Sell
                                    </button>

                                    <button @click="directSellOrder(item)" v-if="item.isSold === 'Scheduled'"
                                        :disabled="!this.ibkrServerStatus || item.isSold === 'Submitted' || item.isSold === 'Rejected' || this.latestData === null"
                                        class="btn btn-danger btn-sm" style="min-width: 80px; margin-left: 10px;">
                                        Direct Sell
                                    </button>
                                </div>
                                <p v-else>N/A</p>
                            </td>
                        </tr>
                        <tr v-if="data.length === 0">
                            <td colspan="14" style="min-width: 1294px; text-align: center; padding: 20px">No Put Data
                                Available</td>
                        </tr>
                    </tbody>

                    <tbody v-if="tableLoading">
                        <tr>
                            <td colspan="14" style="text-align: center; padding: 20px">
                                <b-spinner small></b-spinner>
                            </td>
                        </tr>
                    </tbody>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookie from 'vue-cookie';
import reconnectMainSocket from '@/plugins/socket';
import { convertToUTC, getUserTimezone } from '@/utils/dateUtils';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

export default {
    name: 'OrderData',
    props: {
        side: String
    },
    data() {
        return {
            data: [],
            latestData: null,
            socketMain: null,
            socketSecondary: null,
            ibkrClientStatus: false,
            ibkrServerStatus: false,
            selectedDate: '',
            selectedAction: '',
            selectedStatus: '',
            selectedType: '',
            selectedRight: '',
            showFilters: false,
            tableLoading: false,
            // Settings
            durationArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 45, 60, 90, 120],
            selectedRecurringSellCallDuration: VueCookie.get('selectedRecurringSellCallDuration') || 0,
            selectedRecurringSellPutDuration: VueCookie.get('selectedRecurringSellPutDuration') || 0,
            isRecurringSell: VueCookie.get('isRecurringSell') || false
        };
    },
    computed: {},
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters;
            this.resetFilters();
            this.streamData();
        },

        resetFilters() {
            this.selectedDate = '';
            this.selectedAction = '';
            this.selectedStatus = '';
            this.selectedType = '';
            this.selectedRight = '';
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        async saveRecurringSellPutDuration(value) {
            if (this.selectedRecurringSellPutDuration !== value) {
                this.selectedRecurringSellPutDuration = value
                await this.updateSettings()
            }
        },

        async saveRecurringSellCallDuration(value) {
            if (this.selectedRecurringSellCallDuration !== value) {
                this.selectedRecurringSellCallDuration = value
                await this.updateSettings()
            }
        },

        reOrderData(rootData) {
            let finalData = [];
            let notFoundData = [];

            // Sort buyData and sellData by created_at (earliest to latest)
            let buyData = rootData
                .filter((order) => order.orderAction === 'BUY')
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            let sellData = rootData
                .filter((order) => order.orderAction === 'SELL')
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            let matchedSellOrders = new Set();

            // Process buyData and find the corresponding sell orders
            for (let i = 0; i < buyData.length; i++) {
                let buyOrder = buyData[i];
                let sellOrder = sellData.find((order) => order.parentId === buyOrder.orderId);

                if (sellOrder) {
                    finalData.push(buyOrder); // Add buy order first
                    finalData.push(sellOrder); // Add first corresponding sell order
                    matchedSellOrders.add(sellOrder.orderId); // Mark sell order as matched
                } else {
                    notFoundData.push(buyOrder); // Add buy order to not found list
                }
            }

            // Add any remaining sell orders that do not match any buy orders
            sellData.forEach(sellOrder => {
                if (!matchedSellOrders.has(sellOrder.orderId)) {
                    notFoundData.push(sellOrder);
                }
            });

            // Combine matched orders and unmatched ones, then reverse the final array
            return [...finalData, ...notFoundData].filter(Boolean).reverse();
        },


        async streamData() {
            this.tableLoading = true
            let inputObj = {}
            const userTimezone = getUserTimezone();
            const localTime = DateTime.local().toISO();

            console.log("streamData userTimezone", userTimezone, localTime)

            if (this.selectedDate) {
                inputObj.date = convertToUTC(this.selectedDate, userTimezone)
            } else {
                inputObj.date = convertToUTC(localTime, userTimezone)
            }

            if (this.selectedAction) {
                inputObj.orderAction = this.selectedAction
            }

            if (this.selectedStatus) {
                inputObj.orderStatus = this.selectedStatus
            }

            if (this.selectedType) {
                inputObj.orderType = this.selectedType
            }

            if (this.selectedRight) {
                inputObj.orderRight = this.selectedRight
            }

            console.log("streamData inputObj", inputObj)

            try {
                // Emit the first chunk of data
                this.socketMain.emit('orderData', inputObj, (response) => {
                    console.log("orderData", response?.data?.data)

                    if (response?.data?.data) {
                        let filteredData = response.data.data

                        if (inputObj.orderAction !== "SELL") {
                            if (this.side === "CALL") {
                                const callData = response.data.data.filter((order) => order.right === 'C')
                                filteredData = this.reOrderData(callData)
                            } else {
                                const putData = response.data.data.filter((order) => order.right === 'P')
                                filteredData = this.reOrderData(putData)
                            }
                        }

                        console.log("orderData filteredData" + " " + this.side, filteredData)
                        this.data = filteredData
                    } else {
                        console.warn('No data received in the response from orderData.');
                        this.data = []; // Ensure data is initialized to an empty array in case of no response
                    }
                });
            } catch (error) {
                console.error('Error streaming data:', error);
            } finally {
                this.tableLoading = false;
            }
        },

        formatDate(date) {
            const userTimezone = getUserTimezone();
            return moment(date).tz(userTimezone).format('MM/DD/YYYY hh:mm:ss');
        },

        async updateSettings() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/setting`;
            const settingData = {
                selectedRecurringSellCallDuration: this.selectedRecurringSellCallDuration,
                selectedRecurringSellPutDuration: this.selectedRecurringSellPutDuration,
                isRecurringSell: VueCookie.get('isRecurringSell')
            }

            try {
                const response = await fetch(url, {
                    method: 'POST', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(settingData), // Convert the data to JSON string
                });

                if (response.ok) {
                    await response.json();
                } else {
                    console.error('Failed to update setting:', response.status);
                }
            } catch (error) {
                console.error('Error during updating setting request:', error);
            }
        },

        async executeOrder(action, orderDetails) {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(orderDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
                // this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${action} order:`, error);
                this.showToast(`Error placing ${action} order: ${error.message}`, 'error');
            }
        },

        async executeScheduleOrder(orderDetails) {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/scheduleOrder`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(orderDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                await response.json();
                // this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing sell order:`, error);
                this.showToast(`Error placing sell} order: ${error.message}`, 'error');
            }
        },

        async sellOrder(item) {
            console.log("sellOrder item", item)
            if (!this.scheduleOrder) {
                const orderDetails = {
                    symbol: 'SPX',
                    quantity: item.quantity,
                    orderType: 'MKT',
                    account: process.env.VUE_APP_ACCOUNT_ID,
                    secType: item.secType,
                    exchange: item.exchange,
                    strike: item?.strike,
                    right: item.right,
                    expiry: item.expiry,
                    parentId: item.orderId
                };

                console.log("sellOrder orderDetails", orderDetails)

                await this.executeOrder('sell', orderDetails);
            } else {
                const orderDetails = {
                    symbol: 'SPX',
                    quantity: item.quantity,
                    orderType: 'MKT',
                    account: process.env.VUE_APP_ACCOUNT_ID,
                    secType: item.secType,
                    exchange: item.exchange,
                    strikeBaseValue: this?.latestData?.baseValue || 0,
                    strike: item?.strike,
                    right: item.right,
                    expiry: item.expiry,
                    parentId: item.orderId,
                    orderAction: 'SELL'
                };

                console.log("sellScheduleOrder  orderDetails", { ...orderDetails, timeDuration: item.right === "C" ? this.selectedRecurringSellCallDuration : this.selectedRecurringSellPutDuration })

                await this.executeScheduleOrder({ ...orderDetails, timeDuration: item.right === "C" ? this.selectedRecurringSellCallDuration : this.selectedRecurringSellPutDuration });
            }
        },

        async directSellOrder(item) {
            const orderDetails = {
                symbol: 'SPX',
                quantity: item.quantity,
                orderType: 'MKT',
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: item.secType,
                exchange: item.exchange,
                strike: item?.strike,
                right: item.right,
                expiry: item.expiry,
                parentId: item.orderId
            };

            console.log("sellOrder orderDetails", orderDetails)

            await this.executeOrder('directSell', orderDetails);
        },

        formatBaseValue() {
            return parseFloat(this.latestData?.baseValue).toFixed(2) ?? 'N/A';
        }
    },
    async mounted() {
        this.socketMain = reconnectMainSocket()

        await this.streamData()

        this.socketMain.on('polygonData', data => {
            if (data) {
                this.latestData = { ...data };
            }
        })

        this.socketMain.on('settings', data => {
            this.isRecurringSell = data.isRecurringSell
            VueCookie.set('isRecurringSell', data.isRecurringSell, { expires: '12h' })

            if (this.selectedRecurringSellCallDuration !== data.selectedRecurringSellCallDuration) {
                this.selectedRecurringSellCallDuration = data.selectedRecurringSellCallDuration
                VueCookie.set('selectedRecurringSellCallDuration', data.selectedRecurringSellCallDuration, { expires: '12h' })
            }

            if (this.selectedRecurringSellPutDuration !== data.selectedRecurringSellPutDuration) {
                this.selectedRecurringSellPutDuration = data.selectedRecurringSellPutDuration
                VueCookie.set('selectedRecurringSellPutDuration', data.selectedRecurringSellPutDuration, { expires: '12h' })
            }
        })

        this.socketMain.on('ibkrStatus', data => {
            this.ibkrClientStatus = data?.ibkrClientStatus
            this.ibkrServerStatus = data?.ibkrServerStatus
        })

        this.socketMain.on('fetchOrderData', async data => {
            console.log("fetchOrderData", data)
            if (data) {
                console.log("fetchOrderData 1", data)
                await this.streamData()
            }
        })
    },
};
</script>

<style scoped>
h2 {
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 15px;
}

svg {
    margin-bottom: 4px;
}

td {
    padding: 0.2rem 0.5rem !important;
}

.order-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.order-controls {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    justify-content: space-between;
}

.order-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
}

.order-group {
    display: flex;
    flex-direction: column;
}

.order-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.order-group input,
.order-group select {
    padding: 6px;
}

#type-select,
#start-difference,
#end-difference {
    width: 135px;
}

.search-button:hover {
    background-color: #0056b3;
}

/* Scrollable table styles */
.data-table-container {
    width: 100%;
    height: 305px;
    overflow-y: auto;
    resize: vertical;
    border: 1px solid #ddd;
}

.data-table {
    width: 100%;
    height: 100%;
}

.data-table thead {
    height: 30px;
}

.data-table tbody {
    height: 266px;
}

.data-table th {
    text-align: center;
}

.data-table th,
.data-table td {
    width: auto;
    padding: 4px 6.5px;
    outline: 1px solid #ddd;
}

/* Sticky header */
.data-table th {
    background-color: yellow;
    position: sticky;
    top: 0;
    z-index: 2;
}

/* Striped rows */
.data-table tbody .putRow {
    background-color: #FFCCCC;
    /* Light gray for even rows */
}

.data-table tbody .putRow.isMarket {
    background-color: #f88c8c;
    /* Light gray for even rows */
}

.data-table tbody .callRow {
    background-color: lightgreen;
    /* Light gray for even rows */
}

.data-table tbody .callRow.isMarket {
    background-color: rgb(58, 202, 58);
    /* Light gray for even rows */
}

.sticky-col th {
    z-index: 3;
}

/* Sticky first two columns */
.sticky-col {
    position: sticky;
    z-index: 1;
}

.putRow .sticky-col {
    background-color: #FFCCCC;
}

.putRow.isMarket .sticky-col {
    background-color: #f88c8c;
    /* Light gray for even rows */
}

.callRow .sticky-col {
    background-color: lightgreen;
}

.callRow.isMarket .sticky-col {
    background-color: rgb(58, 202, 58);
    /* Light gray for even rows */
}

.sticky-col-th-1 {
    left: 0;
    z-index: 3 !important;
}

.sticky-col-th-2 {
    left: 100px;
    z-index: 3 !important;
}

.sticky-col-th-17 {
    right: 148px;
    z-index: 3 !important;
}

.sticky-col-th-18 {
    right: 0;
    z-index: 3 !important;
}

.sticky-col-td-1 {
    left: 0;
    z-index: 1 !important;
}

.sticky-col-td-2 {
    left: 100px;
    z-index: 1 !important;
}

.sticky-col-td-17 {
    right: 148px;
    z-index: 1 !important;
}

.sticky-col-td-18 {
    right: 0px;
    z-index: 1 !important;
}

/* Pagination controls */
.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

select,
button {
    height: 38px;
}

/* Media query for responsiveness */
@media (max-width: 1200px) {
    .order-row {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
