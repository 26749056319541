import io from 'socket.io-client'
import VueCookie from 'vue-cookie';

let socketIoMain
// let socketIoSecondary

const reconnectMainSocket = () => {
    // Check if there's already an active connection
    if (socketIoMain) {
        return socketIoMain;
    }

    // If not connected or socket is null, establish a new connection
    socketIoMain = io(process.env.VUE_APP_BACKEND_URL, {
        transports: ['websocket'],
        upgrade: false,
        origin: '*',
        auth: { token: VueCookie.get('authToken') },
    });

    return socketIoMain;
};

// export const reconnectSecondarySocket = () => {
//     // Check if there's already an active connection
//     if (socketIoSecondary) {
//         return socketIoSecondary;
//     }

//     // If not connected or socket is null, establish a new connection
//     socketIoSecondary = io(process.env.VUE_APP_SOCKET_SERVER_URL, {
//         transports: ['websocket'],
//         upgrade: false,
//         origin: '*',
//         auth: { token: VueCookie.get('authToken') },
//     });

//     return socketIoSecondary;
// };

export const disconnectSockets = () => {
    if(socketIoMain) {
        socketIoMain.disconnect();
    }

    // if(socketIoSecondary) {
    //     socketIoSecondary.disconnect();
    // }
}

export default reconnectMainSocket;